<template>
  <div class="home">
    <Spinner v-if="showSpinner"></Spinner>
    <b-alert :show="!favoritePostList.length" variant="success"
      >You have not Boookmarked any posts yet! Bookmark post to access it
      anytime.</b-alert
    >

    <b-row class="mx-0">
      <b-col cols="12" lg="9" class="px-0 px-md-2">
        <div v-if="favoritePostList.length">
          <div
            :class="{ 'blur-background': showSpinner }"
            v-for="post in favoritePostList"
            :key="post._id"
          >
            <PostViewer :key="post._id" :article="post" read-more></PostViewer>
          </div></div
      ></b-col>
      <b-col cols="3" class="px-1"><TrendingPosts></TrendingPosts></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BAlert } from "bootstrap-vue";

export default {
  name: "Home",

  components: {
    PostViewer: () => import(/* webpackPrefetch: true */ "./PostViewer.vue"),
    Spinner: () =>
      import(/* webpackPrefetch: true */ "../components/Spinner.vue"),
    TrendingPosts: () =>
      import(/* webpackPrefetch: true */ "../components/TrendingPosts.vue"),
    BAlert,
  },

  data() {
    return {
      showSpinner: false,
    };
  },

  computed: {
    ...mapGetters(["bookmarkedLists", "favoritePostList"]),
  },

  created() {
    this.getAllArticles();
  },

  methods: {
    ...mapActions(["getFavoritePostList", "signOut"]),

    async getAllArticles() {
      this.showSpinner = true;
      try {
        await this.getFavoritePostList({ bookmarks: this.bookmarkedLists });
      } catch (e) {
        console.error(e);
        if (e.response.status === 401) {
          this.signOut();
        }
      } finally {
        this.showSpinner = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blur-background {
  opacity: 0.25;
}
</style>
